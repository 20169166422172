<template>
  <div class="main-container">
    <div class="modal-cover" v-show="isShowModal"></div>
    <h5 class="title">회원탈퇴</h5>
    <div class="guide">
      <h5>지금까지 코롬 데이터베이스를 이용해 주셔서 감사합니다</h5>
      <p>회원 탈퇴시, 모든 정보는 삭제되며 복구가 불가합니다.<br/>
        가입시 등록한 회원정보의 경우 개인정보취급방침 기준에 따라 보관 후 폐기됩니다. </p>
      <img src="@/assets/images/IA/img_out.png" alt="out">
    </div>
    <hr>
    <div class="info-container">
      <h5>아이디</h5>
      <input type="text" v-model="id" class="custom-input-box w444 disabled" disabled/>
    </div>
    <div class="info-container">
      <h5>비밀번호</h5>
      <input type="text" v-model="password" class="custom-input-box w444"/>
    </div>
    <hr class="sub-line">
    <div class="btn-line">
      <button class="btn-withdrawal" @click="getLogin">회원탈퇴</button>
      <button class="btn-cancel" @click="cancel">취소</button>
    </div>
    <WithdrawalDialog v-if="isShowModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import WithdrawalDialog from "@/views/user/myPage/WithdrawalDialog";
import {firebase} from "../../../firebase/firebaseConfig";
import contents from "../../../assets/data/contents";

export default {
  name: "UserWithdrawal",
  components: {WithdrawalDialog},
  data() {
    return {
      fbCollection: 'users',
      fbUid: this.$store.state.user.uid,
      id: '',
      password: '',
      isShowModal: false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const self = this
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      defRef.doc(self.fbUid)
          .get()
          .then((querySnapshot) => {
            self.userInfo = querySnapshot.data();
            self.id = self.userInfo.id
          });
    },
    getLogin() {
      const self = this;
      if (self.id.trim().length === 0) {
        alert('아이디를 입력하세요.');
        return
      } else if (self.password.trim().length === 0) {
        alert('비밀번호를 입력하세요.');
        return
      }

      const id = self.id + contents.userMail;
      const password = self.password;

      firebase.auth().signInWithEmailAndPassword(id, password).then(async () => {
        const user = firebase.auth().currentUser;

        user.delete().then(() => {
          self.isShowModal = true;
        });
      }).catch(() => {
        alert('비밀번호를 확인하세요.');
      })
    },

    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
      }
    },
    cancel() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.main-container {
  width: 805px;
  margin: 0 auto;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-top: 40px;
  margin-bottom: 30px;
}

.guide {
  padding: 34px 20px 32px;
  background: #F1F6FD;
  position: relative;
  margin-bottom: 30px;
}

.guide img {
  width: 174px;
  height: 130px;
  position: absolute;
  bottom: 0;
  right: 30px;
}


.guide h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin-bottom: 8px;
}

.guide p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;

}

hr {
  border-top: 2px solid #000000;
  margin: 0 0 35px;
}

.sub-line {
  margin-top: 5px;
  border-top: 1px solid #000000;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}


.info-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 150px;
  margin-right: 10px;
}

.w444 {
  width: 444px;
}

.btn-line {
  text-align: center;
  margin-top: 30px;
  position: relative;
  margin-bottom: 60px;
}

.btn-withdrawal {
  width: 128px;
  height: 40px;
  background: #0A1120;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
  margin-right: 10px;
}

.btn-cancel {
  width: 128px;
  height: 40px;
  background: #8D8D8D;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}

.disabled {
  background: #F8F8FA;
}

</style>
