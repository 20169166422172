<template>
  <div class="user-info-frame">
    <div class="modal-cover" v-show="isShowModal"></div>
    <h5 class="title">회원정보</h5>
    <p class="guide">‘ <strong>*</strong> ’ 항목은 입력 필수 항목입니다.</p>
    <div class="line"></div>
    <div class="info-container">
      <h5>아이디<strong>*</strong></h5>
      <input type="text" v-model="userInfo.id" placeholder="아이디 입력" class="custom-input-box w444 disabled" disabled/>
    </div>
    <div class="info-container">
      <h5>이메일</h5>
      <input type="text" v-model="userInfo.email" placeholder="이메일 입력" class="custom-input-box w444"/>
    </div>
    <div class="info-container">
      <h5>휴대폰번호<strong>*</strong></h5>
      <input type="tel" v-model="userInfo.mobile" placeholder="‘-’없이 번호 입력" maxlength="11" class="custom-input-box w444"/>
    </div>
    <div class="info-container">
      <h5>비밀번호</h5>
      <button class="password-btn" @click="onChangePw">비밀번호 변경</button>
    </div>
    <div class="info-container">
      <h5>이름<strong>*</strong></h5>
      <input type="text" v-model="userInfo.name" placeholder="12자 이내 입력" maxlength="12" class="custom-input-box w444"/>
    </div>
    <div class="info-container">
      <h5>생년월일<strong>*</strong></h5>
      <mdb-date-picker-2 class="input-box bg-white w444 search-select " outline placeholder="날짜 선택" type="text" size="lg" v-model="userInfo.birth" title="생년월일 선택" :options="options"/>
    </div>
    <div class="info-container">
      <h5>성별<strong>*</strong></h5>
      <mdb-form-inline style="width: 444px">
        <mdb-input type="radio" id="gender-1" name="groupOfMaterialRadios2" radioValue="남성" v-model="userInfo.gender" label="남성"/>
        <mdb-input type="radio" id="gender-2" name="groupOfMaterialRadios2" radioValue="여성" v-model="userInfo.gender" label="여성"/>
      </mdb-form-inline>
    </div>
    <div class="line-thin"></div>
    <div class="btn-line">
      <button class="next-btn" @click="updateUserInfo">회원정보 변경</button>
      <button class="withdrawal-btn" @click="withdrawal">회원탈퇴</button>
    </div>

    <UserInfoEditConfirmDialog v-if="isShowModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>

import {mdbDatePicker2, mdbFormInline, mdbInput} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import UserInfoEditConfirmDialog from "@/views/user/myPage/UserInfoEditConfirmDialog";

export default {
  name: "UserInfoEditPage",
  components: {
    UserInfoEditConfirmDialog,
    mdbInput,
    mdbFormInline,
    mdbDatePicker2,
  },
  data() {
    return {
      fbCollection: 'users',
      fbUid: this.$store.state.user.uid,
      yearOptions: [],
      monthOptions: [],
      dayOptions: [],
      isShowModal: false,
      userInfo: {
        birth: '',
        id: '',
        email: '',
        gender: '',
        mobile: '',
        name: '',
      },
      options: {
        month: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월'
        ],
        week: ['일', '월', '화', '수', '목', '금', '토'],
        color: {
          header: 'info',
          headerText: 'white',
          checkedDay: 'info'
        },
        buttons: {
          ok: '확인',
          cancel: '취소',
          clear: '초기화'
        },
      },
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async updateUserInfo() {
      const self = this
      const db = firebase.firestore();
      const firebaseCurrentUser = firebase.auth().currentUser

      if (self.checkValidate()) {
        const defRef = db.collection(self.fbCollection);
        defRef.doc(self.fbUid)
            .update(self.userInfo)
            .then(() => {
              firebaseCurrentUser.updateProfile({
                displayName: self.userInfo.name
              })
                  .then(() => {
                    document.getElementsByClassName('user-name')[0].innerHTML = self.userInfo.name
                    self.isShowModal = true;
                  })
                  .catch((e) => {
                    console.log('error 발생 1', e)
                  })
            })
            .catch((e) => {
              console.log('error 발생 2', e)
            })

        //TODO 각 콜랙션마다 존재하는 이름도 찾아서 변경
        if (self.userName !== self.userInfo.name) {

          const evaluationsInUser = await db.collection('evaluations')
              .where('patientInfo.uid', '==', self.fbUid)
              .get()
          if (!evaluationsInUser.size) return
          const promises = evaluationsInUser.docs.map(async (doc) => {
            await db.collection('evaluations')
                .doc(doc.id)
                .set({patientInfo: {name: self.userInfo.name}}, {merge: true})
          });
          await Promise.all(promises)

          const patientsInUser = await db.collection('patients')
              .where('userInfo.uid', '==', self.fbUid)
              .get()
          if (!patientsInUser.size) return
          const promises2 = patientsInUser.docs.map(async (doc) => {
            await db.collection('patients')
                .doc(doc.id)
                .set({userInfo: {name: self.userInfo.name}}, {merge: true})
          });
          await Promise.all(promises2)

        }
      }
    },
    initData() {
      const self = this
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      defRef.doc(self.fbUid)
          .get()
          .then((querySnapshot) => {
            self.userInfo = querySnapshot.data();
          });
    },
    checkValidate() {
      const self = this;

      //이메일이 있는 경우 확인
      if (self.userInfo.email !== '') {
        if (!self.checkEmail()) {
          return false
        }
      }
      //휴대폰번호 확인
      if (self.userInfo.mobile.trim().length === 0) {
        alert('휴대폰번호를 입력하세요.')
        return false
      }
      if (!self.checkMobile()) {
        return false
      }
      //이름 확인(특수문자 불가)
      if (self.userInfo.name.trim().length === 0) {
        alert('이름을 입력하세요.')
        return false
      }
      if (!self.checkName()) {
        return false
      }
      if (self.userInfo.birth == null || self.userInfo.birth === '') {
        alert('생년월일을 입력하세요.')
        return false
      }
      return true
    },

    checkMobile() {
      const self = this;
      const exptext = /^\d{3}\d{3,4}\d{4}$/;
      const exptext2 = /^\d{3}-\d{3,4}-\d{4}$/;
      if (exptext.test(self.userInfo.mobile) === false && exptext2.test(self.userInfo.mobile) === false) {
        alert('전화번호 형식이 올바르지 않습니다.')
        return false;
      }
      self.userInfo.mobile = self.userInfo.mobile.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1$2$3");
      return true;
    },
    checkName() {
      const self = this;
      const special_regx = /[~!@#$%^&*()_+|<>?:{}]/;

      if (special_regx.test(self.userInfo.name)) {
        alert('이름에 특수문자를 넣을 수 없습니다.')
        return false;
      }
      return true;
    },
    checkEmail() {
      const self = this;
      const exptext = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (exptext.test(self.userInfo.email) === false) {
        //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우
        alert("이메일형식이 올바르지 않습니다.");
        self.userInfo.email = ''
        return false;
      }
      return true;
    },
    withdrawal() {
      this.$router.push('/user/withdrawal')
    },
    onChangePw() {
      this.$router.push('/user/rePassword')
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
      }
    },
  }
}
</script>

<style scoped>
.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0 0 30px 0;
}

.user-info-frame {
  padding: 40px 0 60px;
  width: 805px;
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
}

strong {
  color: #E14F4E;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #000000;
  margin: 40px 0 35px;
}

.line-thin {
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin: 0 auto;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}


.info-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 150px;
  margin-right: 10px;
}

.w444 {
  width: 444px;
}

.w350 {
  width: 350px;
}

/deep/ .search-select .md-form {
  margin: 0 !important;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
  height: 40px;
}

/deep/ .diagnosis-search .form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

.info-container /deep/ .mr-5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-right: 10px !important;
  padding-left: 30px !important;
}

.btn-line {
  text-align: center;
  margin-top: 30px;
  position: relative;
}

.next-btn {
  width: 128px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.prev-btn {
  width: 128px;
  height: 40px;
  margin-right: 10px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.check-double {
  width: 84px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  margin-left: 10px;
}

.disabled {
  background: #F8F8FA;
}

.withdrawal-btn {
  width: 86px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #8D8D8D;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
  position: absolute;
  right: 0;
}

.password-btn {
  width: 444px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
}
</style>

