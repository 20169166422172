<template>
  <div class="home-frame">
    <NavigatorForUser/>
    <UserMain v-if="$route.params.menu === 'main'" class="view-layout"/>
    <UserEvaluation v-else-if="$route.params.menu === 'evaluation'&& $route.params.tool != null"/>
    <UserQnA v-else-if="$route.params.menu === 'qna'"/>
    <UserNotice v-else-if="$route.params.menu === 'notice'"/>
    <UserInfoEditPage v-else-if="$route.params.menu === 'myPage'" class="view-layout"/>
    <UserWithdrawal v-else-if="$route.params.menu === 'withdrawal'" class="view-layout"/>
    <UserRePassword v-else-if="$route.params.menu === 'rePassword'"/>
    <Error404 v-else/>
  </div>
</template>

<script>
import Error404 from "@/views/Error404";
import UserMain from "@/views/user/UserMain";
import UserEvaluation from "@/views/user/UserEvaluation";
import NavigatorForUser from "@/components/NavigatorForUser";
import UserQnA from "@/views/user/qna/UserQnA";
import UserNotice from "@/views/user/notice/UserNotice";
import UserInfoEditPage from "@/views/user/myPage/UserInfoEditPage";
import UserWithdrawal from "@/views/user/myPage/UserWithdrawal";
import UserRePassword from "../common/auth/user/UserRePassword";

export default {
  name: "TherapistHomePage",
  components: {
    UserRePassword,
    UserWithdrawal, UserInfoEditPage, UserNotice, UserQnA, NavigatorForUser, UserEvaluation, UserMain, Error404,},
  date() {
    return {}
  },

}
</script>

<style>
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 30px;
}

.view-layout {
  width: 1140px;
  margin: 0 auto;
}
</style>
