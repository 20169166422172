<template>
  <div class="nav-frame">
    <router-link to="/user/main" class="nav-item " :class="{active : $route.params.menu === 'main'}" v-if="$route.params.menu !== 'evaluation'">
      평가기록
    </router-link>
    <div class="nav-item " :class="{active : $route.params.menu === 'evaluation'}" v-else>
      검사기록
    </div>
    <router-link to="/user/qna" class="nav-item" :class="{active : $route.params.menu === 'qna'}">
      자주묻는 질문
    </router-link>
    <router-link to="/user/notice" class="nav-item" :class="{active : $route.params.menu === 'notice'}">
      공지사항
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NavigatorForUser",
  props: {
    index: Number,
  },
}
</script>

<style scoped>
.nav-frame {
  background-color: #FFFFFF;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #E1E3E6;
}

.nav-item {
  width: 173px;
  height: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #000000;
  line-height: 50px;
  margin-left: 15px;
  text-align: center;
}

.nav-item:first-child {
  margin-left: 0;
}


.active {
  color: #0069CA;
}

</style>
