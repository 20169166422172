<template>
  <div class="frame-box">
    <div class="navi-bar">
      <div class="navi-frame">
        <router-link to="/user/main" class="btn-home">
          <img class="icon-home" src="@/assets/images/IA/icon_solid_home_w.png" alt="">
        </router-link>
        <div class="btn_page">
          <p class="p-depth">평가기록</p>
          <p class="p-devided">〉</p>
          <p class="p-target" v-html="guideTitle"></p>
          <p class="p-devided">〉</p>
          <p class="p-recent">검사 상세정보</p>
        </div>
      </div>
    </div>
    <div class="user-eval-frame">
      <h1 v-html="guideTitle"></h1>
      <div class="info-frame">
        <div class="title-label">
          <img class="icon-info" src="@/assets/images/IA/icon_line_info.png"/>
          <p class="p-label">평가 도구 설명</p>
        </div>
        <p class="p-guide" v-html="guideScript"></p>
      </div>
      <div class="info-frame">
        <div class="bs-flex">
          <div class="title-label">
            <img class="icon-info" src="@/assets/images/IA/icon_history.png" alt=""/>
            <p class="p-label">평가 결과 조회</p>
          </div>
          <img src="@/assets/images/IA/pdf_download.png" alt="download" class="pdf_download" @click="goPdf">
        </div>
        <mdb-line-chart
            :data="lineChartData"
            :options="lineChartOptions"
            :width="1070"
            :height="174"
            v-if="isShowChart"
        />

        <StatisticsFm :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart && selectTool==='fm'"/>
        <StatisticsBb :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='bb'"/>
        <StatisticsSis :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='sis'"/>
        <StatisticsAaut :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='aaut'"/>
        <StatisticsMal :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='mal'"/>
        <StatisticsWmft :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='wmft'"/>
        <StatisticsBbs :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='bbs'"/>
        <StatisticsTug :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='tug'"/>
        <StatisticsFtsts :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='ftsts'"/>
        <StatisticsFm_l :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='fm_l'"/>
        <StatisticsTmwt :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='tmwt'"/>
        <StatisticsSmwt :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='smwt'"/>
        <StatisticsDgi :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='dgi'"/>
        <StatisticsAbc :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='abc'"/>

      </div>
    </div>
  </div>
</template>

<script>
import {mdbLineChart} from 'mdbvue';
import StatisticsWmft from "@/views/therapist/menu/patientsManage/detail/StatisticsWmft";
import StatisticsMal from "@/views/therapist/menu/patientsManage/detail/StatisticsMal";
import StatisticsAaut from "@/views/therapist/menu/patientsManage/detail/StatisticsAaut";
import StatisticsSis from "@/views/therapist/menu/patientsManage/detail/StatisticsSis";
import StatisticsBb from "@/views/therapist/menu/patientsManage/detail/StatisticsBb";
import StatisticsFm from "@/views/therapist/menu/patientsManage/detail/StatisticsFm";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import StatisticsBbs from "@/views/therapist/menu/patientsManage/detail/StatisticsBbs";
import StatisticsTug from "@/views/therapist/menu/patientsManage/detail/StatisticsTug";
import StatisticsFtsts from "@/views/therapist/menu/patientsManage/detail/StatisticsFtsts";
import StatisticsFm_l from "@/views/therapist/menu/patientsManage/detail/StatisticsFm_l";
import StatisticsTmwt from "@/views/therapist/menu/patientsManage/detail/StatisticsTmwt";
import StatisticsSmwt from "@/views/therapist/menu/patientsManage/detail/StatisticsSmwt";
import StatisticsDgi from "@/views/therapist/menu/patientsManage/detail/StatisticsDgi";
import StatisticsAbc from "@/views/therapist/menu/patientsManage/detail/StatisticsAbc";

export default {
  name: "UserEvaluation",
  components: {
    StatisticsWmft,
    StatisticsMal,
    StatisticsAaut,
    StatisticsSis,
    StatisticsBb,
    StatisticsFm,
    StatisticsBbs,
    StatisticsTug,
    StatisticsFtsts,
    StatisticsFm_l,
    StatisticsTmwt,
    StatisticsSmwt,
    StatisticsDgi,
    StatisticsAbc,
    mdbLineChart,
  },
  data() {
    return {
      toolType: this.$route.params.tool,
      fbUserUid: this.$store.state.user.uid,
      patientKey: this.$route.params.id,
      selectTool: '',
      guideTitle: '',
      guideScript: '',
      isShowChart: false,
      dataTableList: [],
      lineChartData: {
        labels: [],
        datasets: []
      },
      lineChartOptions: {
        legend: {
          fullWidth: false,
          position: 'bottom'
        },
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ]
        }
      },
      guideText: [
        {
          type: 'fm',
          title: '전반적 상지 손상 검사(FM)',
          script: '뇌졸중 후 환자의 회복을 평가 및 측정하는 도구로 상지의 운동기능을 측정하기위해 사용되는 평가도구입니다.</br>' +
              '상지의 반사, 굴곡 시너지, 신전 시너지 움직임 및, 손목과 손가락의 움직임, 협응성등을 평가합니다.</br>' +
              '66점 만점으로 점수가 낮을수록 기능이 낮고, 점수가 높을수록 환자의 운동기능이 높음을 의미합니다.'
        },
        {
          type: 'wmft',
          title: '상지 운동 검사(WMFT)',
          script: '상지의 기능적 운동 능력을 평가하는 것으로 해당 움직임 시 시간과 움직임의 질을 평가하는 도구입니다.<br/>' +
              '움직임 시간이 짧을수록 움직임의 질 점수가 높을 수록 기능의 수준이 높다는 것을 의미합니다.'
        },
        {
          type: 'bb',
          title: '손재주 검사 - 블록 옮기기(BB)',
          script: '박스와 블록 검사 (Box and Block, BB)은 손상측 손과 비손상측 손의 잡기(grasping) 능력을 보기 위해 사용되는 평가도구 입니다.  <br/>' +
              '1분 동안 옮긴 큐브의 개수가 높을수록 환자의 잡기 능력이 높음을 의미합니다.'
        },
        {
          type: 'mal',
          title: '상지 사용 능력 검사(MAL)',
          script: '개인의 실제 일상생활 속에서 손상측 상지를 얼마나 많이 그리고 잘 사용하였는지 평가하는 도구입니다.<br/>' +
              '점수가 높을 수록 손상측 상지를 많이 사용하며 잘 사용함을 의미합니다.'
        },
        {
          type: 'sis',
          title: '삶의 질 검사 - 우울증 검사(SIS)',
          script: '뇌졸중 환자들을 대상으로 뇌졸중에 의한 신체적인 면, 일상생활 동작, 감성, 의사소통, 기억, 사회 참여 등의 9가지 영역에서 변화 정도를 측정하기 위해 고안된 평가척도입니다.</br>' +
              '각 영역에 따라 척도가 달라질 수 있지만, 9번 영역을 제외한 나머지 영역은 점수가 낮을수록 뇌졸중으로 인해 받게 되는 영향이 적음을 의미합니다. '
        },
        {
          type: 'aaut',
          title: '일상 기능 검사(AAUT)',
          script: '손상측의 실제 사용량이 얼마나 되는지 알아보는 검사입니다. 점수가 높을수록 사용량이 많고 움직임의 질이 좋음을 의미합니다.'
        },
        {
          type: 'bbs',
          title: '버그 균형 척도(BBS)',
          script: '성인의 정적 균형과 낙상 위험을 평가하는 14개 항목의 객관적인 척도입니다.<br/>' +
              '0-20점은 균형 능력 손상, 21-40점은 허용 가능한 균형, 41-56점은 균형 능력이 좋음을 나타냅니다.'
        },
        {
          type: 'tug',
          title: '이동성 검사(TUG)',
          script: 'Timed Up And Go Test는 사람의 이동성을 측정하는데 사용되는 하지평가도구로 정적인 균형과 동적인 균형을 모두 필요로 합니다. <br/>' +
              '제한시간 없이 시간을 측정하며, 점수의 해석은 ‘10초 이내 = 스스로 완전히 활동 가능, 20초 이내 = 기본적 활동 가능, 30초 이상 = 보조 필요로 할 수 있다’ 입니다.'
        },
        {
          type: 'ftsts',
          title: '근력/균형조절 검사 - 앉았다 일어나기(FTSTS)',
          script: '성인의 정적 균형과 낙상 위험을 평가하는 14개 항목의 객관적인 척도입니다.  <br/>' +
              '0-20점은 균형 능력 손상, 21-40점은 허용 가능한 균형, 41-56점은 균형 능력이 좋음을 나타냅니다.'
        },
        {
          type: 'fm_l',
          title: '전반적 하지 손상 검사(FM)',
          script: '뇌졸중 후 환자의 회복을 평가 및 측정하는 도구로 하지의 운동기능을 측정하기 위해 사용되는 평가도구입니다. <br/>' +
              '하지의 반사, 굴곡 시너지, 신전 시너지 움직임 및, 협응성 등을 평가하며 0에서 2점 사이의 점수를 평가자가 부여하게 됩니다.<br/>' +
              ' 총 34점 만점으로 점수가 낮을수록 기능이 낮고, 점수가 높을수록 환자의 운동기능이 높음을 의미합니다.'
        },
        {
          type: 'tmwt',
          title: '10m 걷기 검사(10MWT)',
          script: '10Meter Walk Test (10MWT)는 단거리 보행 속도 및 능력을 보는 평가도구입니다. <br/>' +
              ' 평소 걸음과 빠른 걸음 각각 평균 속도를 계산하며, 초기 평가 후 1개월마다 재평가를 시행합니다.'
        },
        {
          type: 'smwt',
          title: '6분 걷기 검사(6MWT)',
          script: '6Minute Walk Test (6MWT), 6분 걷기 검사는 환자의 운동 능력을 객관적으로 측정하는 평가도구입니다. <br/>' +
              '6분 동안 환자가 걸은 거리를 측정하고 1분마다 피로도와 호흡 곤란도를 측정합니다.'
        },
        {
          type: 'dgi',
          title: '동적 보행 검사(DGI)',
          script: 'Dynamic Gait Index (DGI), 동적 보행 검사는 평평한 지면에서의 보행, 보행 속도 변경하기, 보행하면서 머리를 옆으로 돌리기 같은 <br/>' +
              '과제가 포함된 보행능력을 정량화 하여 측정할 수 있는 도구입니다. 0점에서 3점까지 4점 척도로, 점수가 높을수록 보행에 손상이 적은 것을 의미합니다.'
        },
        {
          type: 'abc',
          title: '활동 특이적 균형 자신감 척도(ABC Scales)',
          script: 'ABC scale은 균형을 잃지 않고 다양한 활동을 수행하는 것에 대한 개인의 균형 자신감을 평가하는 16가지 항목의 자가 보고 측정 검사입니다. <br/>' +
              '항목은 0에서 100까지 등급 척도를 이용하며, 점수가 높을수록 균형 능력에 자신이 있음을 나타냅니다.'
        },
      ],
      data: {
        columns: [],
        rows: []
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const self = this;

      await self.showGuide()
      await self.searchStatistics();
    },

    searchStatistics() {
      const self = this;
      self.resetChartData();
      self.isShowChart = false;
      let docBasic = firebase.firestore().collection('evaluations')
          .where('patientInfo.uid', '==', self.fbUserUid)
          .where('state', '==', 1);

      if (self.selectTool !== '') {
        if (self.selectTool === 'fm') {
          docBasic = docBasic
              .where('tools.fm.state', '==', 2)
        } else if (self.selectTool === 'wmft') {
          docBasic = docBasic
              .where('tools.wmft.state', '==', 2)
        } else if (self.selectTool === 'sis') {
          docBasic = docBasic
              .where('tools.sis.state', '==', 2)
        } else if (self.selectTool === 'mal') {
          docBasic = docBasic
              .where('tools.mal.state', '==', 2)
        } else if (self.selectTool === 'bb') {
          docBasic = docBasic
              .where('tools.bb.state', '==', 2)
        } else if (self.selectTool === 'aaut') {
          docBasic = docBasic
              .where('tools.aaut.state', '==', 2)
        } else if (self.selectTool === 'bbs') {
          docBasic = docBasic
              .where('tools.bbs.state', '==', 2)
        } else if (self.selectTool === 'tug') {
          docBasic = docBasic
              .where('tools.tug.state', '==', 2)
        } else if (self.selectTool === 'ftsts') {
          docBasic = docBasic
              .where('tools.ftsts.state', '==', 2)
        } else if (self.selectTool === 'fm_l') {
          docBasic = docBasic
              .where('tools.fm_l.state', '==', 2)
        } else if (self.selectTool === 'tmwt') {
          docBasic = docBasic
              .where('tools.tmwt.state', '==', 2)
        } else if (self.selectTool === 'smwt') {
          docBasic = docBasic
              .where('tools.smwt.state', '==', 2)
        } else if (self.selectTool === 'dgi') {
          docBasic = docBasic
              .where('tools.dgi.state', '==', 2)
        } else if (self.selectTool === 'abc') {
          docBasic = docBasic
              .where('tools.abc.state', '==', 2)
        }
      }

      self.realQuery(docBasic)
    },
    realQuery(doc) {
      const self = this;
      self.dataTableList.splice(0)
      let fmLineData = {
        label: self.selectTool,
        backgroundColor: "rgba(36, 194, 153, 0.1)",
        borderColor: "rgba(36, 194, 153, 0.6)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData2 = {
        label: self.selectTool,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData3 = {
        label: self.selectTool,
        backgroundColor: "rgba(151,187,205,0.2)",
        borderColor: "rgba(151,187,205,1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData4 = {
        label: self.selectTool,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }


      doc.orderBy('regDate', 'asc')
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          self.statistics = '데이터가 없습니다.'
          return
        }


        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDate(date);
          // 챠트라벨넣기
          self.lineChartData.labels.push(getDate(date));
          if (self.selectTool === 'fm') {
            fmLineData.data.push(_data.tools.fm.totalAll);
          } else if (self.selectTool === 'bb') {
            fmLineData.data.push(_data.tools.bb.la)
            fmLineData2.data.push(_data.tools.bb.ma)
          } else if (self.selectTool === 'sis') {
            fmLineData.data.push(_data.tools.sis.totalAvg)
            fmLineData2.data.push(_data.tools.sis.totalSum)
          } else if (self.selectTool === 'aaut') {
            fmLineData.data.push(_data.tools.aaut.totalAouScore)
            fmLineData2.data.push(_data.tools.aaut.totalQomScore)
            // fmLineData3.data.push(_data.tools.aaut.totalAouAvg)
            // fmLineData4.data.push(_data.tools.aaut.totalQomAvg)
          } else if (self.selectTool === 'mal') {
            fmLineData.data.push(_data.tools.mal.totalAouScore)
            fmLineData2.data.push(_data.tools.mal.totalQomScore)
            // fmLineData3.data.push(_data.tools.mal.totalAouAvg)
            // fmLineData4.data.push(_data.tools.mal.totalQomAvg)
          } else if (self.selectTool === 'wmft') {
            fmLineData.data.push(_data.tools.wmft.totalAvg)
          } else if (self.selectTool === 'bbs') {
            fmLineData.data.push(_data.tools.bbs.total)
          } else if (self.selectTool === 'tug') {
            fmLineData.data.push(_data.tools.tug.time)
          } else if (self.selectTool === 'ftsts') {
            fmLineData.data.push(_data.tools.ftsts.time)
          } else if (self.selectTool === 'fm_l') {
            fmLineData.data.push(_data.tools.fm_l.totalAll)
          } else if (self.selectTool === 'tmwt') {

            _data.tools.tmwt['average_self_selected_speed'] = _data.tools.tmwt.self_selected_speed.average_self_selected
            _data.tools.tmwt['average_fast_speed'] = _data.tools.tmwt.fast_speed.average_self_selected
            fmLineData.data.push(_data.tools.tmwt.average_self_selected_speed)
            fmLineData2.data.push(_data.tools.tmwt.average_fast_speed)
          } else if (self.selectTool === 'smwt') {

            _data.tools.smwt['total'] =
                _data.tools.smwt.distance_1 + _data.tools.smwt.distance_2 + _data.tools.smwt.distance_3 +
                _data.tools.smwt.distance_4 + _data.tools.smwt.distance_5 + _data.tools.smwt.distance_6
            fmLineData.data.push(_data.tools.smwt.total)
          } else if (self.selectTool === 'dgi') {
            fmLineData.data.push(_data.tools.dgi.total)
          } else if (self.selectTool === 'abc') {
            fmLineData.data.push(_data.tools.abc.total)
          }

          self.statisticsList.push(_data);
          self.dataTableList.push(_data);
        });

        self.setLabel(fmLineData, fmLineData2, fmLineData3, fmLineData4);
        self.isShowChart = true;
      });

    },

    setLabel(fmLineData, fmLineData2, fmLineData3, fmLineData4) {
      const self = this;
      switch (self.selectTool) {
        case 'fm':
          fmLineData.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'bb':
          fmLineData.label = 'Less-Affected Hand'
          fmLineData2.label = 'More-Affected Hand'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'sis':
          fmLineData.label = '평균'
          fmLineData2.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'aaut':
        case 'mal':
          fmLineData.label = 'AOU Score'
          fmLineData2.label = 'QOM Score'
          fmLineData3.label = 'AOU AVG'
          fmLineData4.label = 'QOM AVG'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          // self.lineChartData.datasets.push(fmLineData3)
          // self.lineChartData.datasets.push(fmLineData4)
          return
        case 'wmft':
          fmLineData.label = '평균'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'bbs':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'tug':
          fmLineData.label = '시간(초)'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'ftsts':
          fmLineData.label = '시간(초)'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'fm_l':
          fmLineData.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'tmwt':
          fmLineData.label = 'self selected speed'
          fmLineData2.label = 'fast speed'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'smwt':
          fmLineData.label = '총 거리'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'dgi':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'abc':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
      }
    },

    resetChartData() {
      const self = this;
      self.lineChartData.datasets = [];
      self.lineChartData.labels = [];
      self.statisticsList = [];
    },


    showGuide() {
      const self = this;
      const selTool = self.guideText.find(x => x.type === self.toolType)

      self.selectTool = self.toolType
      self.guideTitle = selTool.title
      self.guideScript = selTool.script
    },

    goPdf() {
      const self = this;
      self.$router.push({
        name: 'pdfUser',
        query: {
          selectTool: self.selectTool,
        }
      });
    },
  }
}
</script>

<style scoped>

.frame-box {
  background: #F8F8FA !important;
}

.icon-home {
  width: 20px;
}

.btn-home {
  width: 50px;
  height: 50px;
  background: #00519C;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navi-bar {
  height: 50px;
  background: #0069CA;
}

.navi-frame {
  width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
}

.btn_page {
  padding-left: 10px;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn_page > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.p-devided {
  padding-left: 6px;
}

.p-recent {
  font-weight: 700 !important;
}

.user-eval-frame {
  width: 1140px;
  margin: 0 auto;
  padding: 40px 15px;
}

h1 {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 30px;
}

.info-frame {
  padding: 23px 20px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.title-label {
  width: 124px;
  height: 28px;
  border-radius: 100px;
  padding: 0px 10px;
  background: #0A1120;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.icon-info {
  width: 18px;
}

.p-label {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-left: 2px;
  white-space: nowrap;
}

.p-guide {
  font-size: 14px;
  color: #8d8d8d;
  line-height: 20px;
}

.img-chart {
  width: 1070px;
  height: 174px;
  margin-bottom: 40px;
}

.bs-flex {
  display: flex;
  justify-content: center;
}

.pdf_download {
  width: 185px !important;
  height: 42px !important;
  margin-left: auto;
  cursor: pointer;
}
</style>
