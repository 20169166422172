<template>
  <div class="main-frame">
    <div class="main-container" v-if="!isShowDetail">
      <h4 class="main-title" v-html="title"></h4>
      <div class="bs-flex">
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
        </div>
        <button class="custom-btn-box search-btn">
          검색
        </button>
      </div>
      <div class="table-container">
        <mdb-datatable-2
            v-model="data"
            class="text-center"
            borderless
            hover
            @selected="onSelected($event)"
            :searching="{value: search, field: fieldName} "
            noFoundMessage="데이터가 없습니다."/>
      </div>
    </div>
    <div class="detail-container" v-if="isShowDetail">
      <div class="bs-flex">
        <div style="margin-left: auto">
          <button class="table-btn" @click="prevBoard">
            <img src="@/assets/images/IA/icon_prev_post.png" alt="list">
            이전글
          </button>
          <button class="table-btn" @click="nextBoard">
            <img src="@/assets/images/IA/icon_next_post.png" alt="list">
            다음글
          </button>
          <button class="table-btn" @click="refList">
            <img src="@/assets/images/IA/icon_list_view.png" alt="list">
            목록
          </button>
        </div>
      </div>
      <div class="detail-sub-container">
        <h5 v-html="subTitle" class="subTitle"></h5>
        <div class="bs-flex">
          <p v-html="subDate" class="subDate"></p>
          <p class="subViewTitle">조회수 </p>
          <p v-html="subView" class="subView"></p>
        </div>
        <hr class="line">
        <mdb-input type="textarea" id="editor" class="textarea-desc" outline v-model="subScript" readOnly :rows="10"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mdbDatatable2, mdbInput} from "mdbvue";
import qna_columns from "@/assets/data/user/qna_columns";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate, getDateTime} from "@/lib/prettyDate";

export default {
  name: "UserNotice",
  components: {
    mdbDatatable2, mdbInput
  },
  data() {
    return {
      fbCollection: 'notice',
      title: '공지사항',
      data: {
        columns: qna_columns,
        rows: []
      },
      search: '',
      fieldName: '',
      isShowDetail: false,
      subNo: 0,
      subTitle: '자주묻는 질문 제목이 들어갑니다',
      subDate: '2021-03-12 21:32',
      subView: '45,123',
      subScript: 'The purpose of this study is to investigate the conception and developmental process of stretching in sports physical therapy. This study is to find conception of stretching, feature and effect, principles and fundamental rule, consideration of enforcement and developmental process in order to use the basic material which is very helpful in the every field and the scene of sports needing stretching. Flexibility is the ability to move muscles and joints through their full ranges of motion. Flexibility is developed by stretching. About player who insufficiency of flexibility, patient and disabled person who restrict of range of motion, older adult who reduce of flexibility, promote of flexibility for upgrading stability and efficiency of body on the based of scientific principles is completed by stretching. The method of stretching has been developed with passive stretching, CR, PNF stretching, PIC stretching, MET stretching in the order.',
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const self = this
      self.getFaq()
    },
    getFaq() {
      const self = this;
      const db = firebase.firestore();
      let count = 0;
      db.collection(self.fbCollection).orderBy("regDate", "asc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          return
        }
        querySnapshot.forEach((doc) => {
          count += 1;
          const _data = doc.data();
          _data["docId"] = doc.id;
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDate(date);
          const dateTime = new Date(_data.regDate.seconds * 1000);
          _data.regDateTime = getDateTime(dateTime);
          _data.orderNumber = count;
          self.data.rows.push(_data);
        });
      })
    },

    onSelected(value) {
      const self = this;
      self.isReference = false;
      self.selected = value;
      if (self.selected != null) {
        self.subNo = self.selected.orderNumber;
        self.subTitle = self.selected.title;
        self.subDate = self.selected.regDate;
        self.subScript = self.selected.desc;
        self.subView = self.selected.view + 1;
        self.isShowDetail = true;
        self.increaseCount(self.selected)
        const selIndex = self.data.rows.findIndex((e) => e.docId === self.selected.docId)
        self.data.rows[selIndex].view = self.subView
      }
    },
    increaseCount(selected) {
      const self = this;
      const db = firebase.firestore();
      db.collection(self.fbCollection)
          .doc(selected.docId)
          .set({
            view: firebase.firestore.FieldValue.increment(+1)
          }, {merge: true})
    },

    refList() {
      const self = this;
      self.isShowDetail = false;
    },

    prevBoard() {
      const self = this;
      const ref = self.data.rows;
      let res = ref.filter(it => it.orderNumber === self.subNo - 1);
      if (res.length !== 0) {
        self.subNo = res[0].orderNumber;
        self.subTitle = res[0].title;
        self.subDate = res[0].regDate;
        self.subScript = res[0].desc;
        self.subView = res[0].view;
      } else {
        alert('처음 글입니다.');
      }
    },

    nextBoard() {
      const self = this;
      const ref = self.data.rows;
      let res = ref.filter(it => it.orderNumber === self.subNo + 1);
      if (res.length !== 0) {
        self.subNo = res[0].orderNumber;
        self.subTitle = res[0].title;
        self.subDate = res[0].regDate;
        self.subScript = res[0].desc;
        self.subView = res[0].view;
      } else {
        alert('마지막 글입니다.');
      }
    },
  }
}
</script>

<style scoped>
.main-frame {
  background: #F8F8FA;
}

.main-container {
  width: 1140px;
  margin: 0 auto;
  padding: 40px 15px;
}

.main-title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0;
}

.bs-flex {
  display: flex;
}

.search-input {
  width: 282px;
  padding-left: 30px;
  background: #EDEDF1;
  font-size: 16px;
}

.icon-input {
  position: relative;
  margin-left: auto;
}

/deep/ .icon-input ::placeholder {
  font-size: 16px !important;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-btn {
  margin-left: 10px;
  width: 78px;
  height: 40px;
  background: #16476E;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.table-container {
  margin-top: 20px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
}

/deep/ .mdb-datatable thead th {
  font-size: 16px;
  font-weight: 700;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F1F6FD;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(227, 238, 252, 1);
}

.table-btn {
  background: #EDEDF1;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  border: 0;
  margin-left: 10px;
  padding: 2px 10px;
}

.table-btn img {
  text-align: left;
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.detail-container {
  width: 1140px;
  margin: 0 auto;
  padding: 40px 15px;
}

.detail-sub-container {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 5px;
  margin-top: 23px;
  margin-left: 32px;
}

.subDate {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin-left: 32px;
  margin-bottom: 23px;

}

.subViewTitle {
  margin: 0 8px 0 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.subView {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.line {
  width: 100%;
  margin: 0;
}

/deep/ .textarea-desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/deep/ .textarea-desc textarea {
  padding: 23px 32px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  border: 0 !important;
  resize: none;
  color: #272833 !important;
  -webkit-text-fill-color: #272833;
  -webkit-opacity: 1;
  margin: 0;
  overflow: overlay;
}
</style>
