<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        회원정보 변경 완료
      </h5>
    </div>
    <div class="modal-center">
      <p>회원정보 변경이 완료되었습니다.</p>
    </div>
    <div class="modal-footer">
      <button class="primary-blue custom-btn-box btn-confirm" @click="btnConfirm">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfoEditConfirmDialog",
  methods: {
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal', 0)
    }
  }
}
</script>

<style scoped>
p {
  font-size: 14px !important;
  margin-top: 8px;
}

button, strong {
  font-size: 16px !important;
}

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 60px;
  background: #F8F8FA;
}

.modal-center h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 5px;
}

.modal-center h5 strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-left: 13px;
  margin-bottom: 7px;
}

.modal-center p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #000000;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 14px;
  line-height: 36px;
  background-color: white;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0125em;
  color: rgba(24, 24, 25, 0.9);
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #E14F4E;
  margin-bottom: 20px;
  margin-top: 8px;
}

.btn-confirm {
  width: 84px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}


</style>
